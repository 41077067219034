import {
  useApi,
  useCountryIsoCode,
  useWebApplication,
} from '@axteams-one/bws-cloud-discovery/react'
import { useFlag } from '@axteams-one/bws-cloud-flags/react'
import { MapProvider } from '@axteams-one/bws-cloud-maps/react'
import { useTrackHeartbeats } from '@axteams-one/bws-cloud-metrics/react'
import { TimeSourceProvider } from '@axteams-one/bws-cloud-time-source/react'
import { PopulatedTopbar } from '@axteams-one/populated-topbar'
import { datadogRum } from '@datadog/browser-rum'
import { Toaster, makeStyles, tokens } from '@fluentui/react-components'
import { Outlet } from 'react-router-dom'

import { LoadingPage } from '../components/Loading'
import { Sidebar } from '../components/Sidebar'
import { config } from '../config'
import { HEADER_HEIGHT, TOASTER_ID } from '../constants'
import { useCrypto } from '../hooks/useCrypto'
import { useFullscreenElement } from '../hooks/useFullscreenElement'
import { useRedirectIfNotLoggedIn } from '../hooks/useRedirectIfNotLoggedIn'
import { useReloadOnWakeUp } from '../hooks/useReloadOnWakeUp'
import { CurrentOrganizationProvider } from '../providers/CurrentOrganizationProvider'
import { NotificationsProvider } from '../providers/NotificationsProvider'
import { Flags } from '../util/flags'

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: tokens.colorNeutralBackground4,
  },
  topbar: {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    height: HEADER_HEIGHT,
    zIndex: 100,
  },
  main: {
    display: 'flex',
    minHeight: '100vh',
    paddingTop: HEADER_HEIGHT,
    '@supports (min-height: 100dvh)': {
      minHeight: '100dvh',
    },
  },
  sidebar: {
    position: 'sticky',
    top: HEADER_HEIGHT,
    alignSelf: 'start',
    padding: `${tokens.spacingVerticalXXXL} ${tokens.spacingHorizontalXS} 0`,
  },
  content: {
    flexGrow: 1,
    backgroundColor: tokens.colorNeutralBackground3,
    borderTopLeftRadius: tokens.borderRadiusXLarge,
    boxShadow: tokens.shadow8,
  },
})

function App() {
  // TODO When this flag is removed, move this so that it's run once
  // globally and not on every render.
  const enableDataDogRum = useFlag(Flags.ENABLE_DATADOG_RUM)?.enabled === true
  if (enableDataDogRum) {
    initDataDog()
  }

  useTrackHeartbeats()
  useRedirectIfNotLoggedIn()
  useReloadOnWakeUp()

  const styles = useStyles()
  const manual = useWebApplication('bwl-manual')

  const timeProxy = useApi('time-proxy')

  const fullscreenElement = useFullscreenElement()

  const countryIsoCode = useCountryIsoCode()

  // TODO(alexg): For now this is most likely the best place as pretty much all
  // functionality requires encryption. If we don't plan on using encryption
  // immediately in some part of the app, this decision can be reconsidered.
  const cryptoModuleIsLoading = useCrypto()

  // Show loading indicator until we have everything we need to show our first
  // view to the user
  if (cryptoModuleIsLoading) {
    return <LoadingPage />
  }

  const helpArea = {
    about: {
      version: import.meta.env.VITE_APP_VERSION || 'dev build',
      thirdPartyLicencesUrl: `${window.location.origin}/licenses.txt`,
    },
    webManual: { link: manual?.uri },
  }

  return (
    <div className={styles.page}>
      <div className={styles.topbar}>
        <PopulatedTopbar helpArea={helpArea} />
      </div>
      <main className={styles.main}>
        <div className={styles.sidebar}>
          <Sidebar />
        </div>
        <div className={styles.content}>
          <CurrentOrganizationProvider>
            <MapProvider
              apiKey={config.mapApiKey}
              region={countryIsoCode ?? config.defaultCountryIsoCode}
            >
              <NotificationsProvider>
                <TimeSourceProvider endpoint={timeProxy?.uri || ''}>
                  <Toaster
                    toasterId={TOASTER_ID}
                    position="top-end"
                    offset={{ vertical: 48 }}
                    limit={5}
                    mountNode={fullscreenElement}
                  />
                  <Outlet />
                </TimeSourceProvider>
              </NotificationsProvider>
            </MapProvider>
          </CurrentOrganizationProvider>
        </div>
      </main>
    </div>
  )
}

/** Initialize DataDog Rum Browser Monitoring to track user interactions. */
function initDataDog() {
  datadogRum.init({
    applicationId: '56141bc4-7eb8-4763-8a9d-27c59143a7f8',
    clientToken: 'pubb8cb9a311665f729136c077319e4b10f',
    site: 'datadoghq.eu',
    service: 'axis-body-worn-live',
    env: config.environment,
    version: import.meta.env.VITE_APP_VERSION || 'dev_build',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    trackResources: false,
    trackLongTasks: false,
    defaultPrivacyLevel: 'mask',
    enablePrivacyForActionName: true,
    silentMultipleInit: true,
  })

  datadogRum.setGlobalContext({
    axisinstance: config.environment,
    team: 'bodyworn',
    owner: 'bodyworn',
  })
}

export { App }
