import { useEffect } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { getCachedGroup } from '../util/group'
import { Stream } from '../util/stream'

type UseViewedStreamsProps = {
  streams?: Stream[]
  streamId: string
  groupId: string
}

/** Returns list of viewed streams from local storage */
export function useViewedStreams({
  streams,
  streamId,
  groupId,
}: UseViewedStreamsProps) {
  const [viewedStreams, setViewedStreams] = useLocalStorage<string[]>(
    'viewedStreams',
    []
  )

  useEffect(() => {
    if (!streams) {
      return
    }

    setViewedStreams((previous) => {
      // Only include existing streams.
      const existingViewedStreams = new Set(
        streams
          .map((stream) => stream.id)
          .filter((streamId) => previous.includes(streamId))
      )

      // Add current stream
      if (streamId) {
        existingViewedStreams.add(streamId)
        // Or streams of current group
      } else if (groupId) {
        const group = getCachedGroup(groupId)

        const groupStreams = new Set(
          streams
            .filter(
              (stream) => group?.bearers?.has(stream.bearerId) && stream.ongoing
            )
            .map((stream) => stream.id)
        )

        existingViewedStreams.union(groupStreams)
      }

      return [...existingViewedStreams]
    })
  }, [groupId, setViewedStreams, streamId, streams])

  return viewedStreams
}
